.Chart {
  border-radius: 3px;
  font-size: 1em;
  font-weight: bold;
  margin: 0;
  padding: 0.5em;
}
.Chart.success {
  background-color: hsla(120, 100%, 25%, 1);
  color: hsla(0, 0%, 100%, 1);
}
.Chart.warn {
  background-color: hsla(39, 100%, 50%, 1);
  color: hsla(0, 0%, 0%, 1);
}
.Chart.fail {
  background-color: hsla(0, 100%, 50%, 1);
  color: hsla(0, 0%, 100%, 1);
}
