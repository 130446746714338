.map-wrapper {
  height: 500px;
  margin: 0 auto;
  position: relative;
}
#map {
  border-radius: 3px;
  bottom: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.mapFull {
  height: 800px;
}

.MapProgress h4 {
  background: hsla(240, 100%, 50%, 0.075);
  border-radius: 3px;
  color: hsla(240, 100%, 50%, 1);
  display: inline-block;
  font-size: 0.75em;
  font-style: italic;
  font-weight: normal;
  margin: 0 0 1em;
  padding: 0.5em 0.75em;
}
