.diagram header h2 {
  margin-bottom: 0;
}
.diagram header p {
  color: slategray;
  font-size: 0.75em;
  font-style: italic;
  margin-top: 0;
}
.diagram table {
  font-size: 0.75em;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 2em 0;
}
table th {
  font-weight: bold;
}
thead th,
thead td {
  background-color: #f1f1f1;
}
th,
td {
  background-color: #ffffff;
  border: 1px solid #5b616b;
  font-weight: 400;
  padding: 1rem 1.5rem;
  text-align: left;
}
th.center,
td.center {
  text-align: center;
}
td.connection {
  font-family: 'Courier New', Courier, monospace;
}
.capitalize {
  display: block;
  text-transform: capitalize;
}
