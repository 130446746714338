.Status {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
}
.Status div {
  width: 50%;
}
.Status .accountActions {
  text-align: right;
}
.Status p {
  display: inline-block;
  margin: 0 1em 0 3em;
}
.Status button {
  background-color: blue;
  border: none;
  border-radius: 3px;
  color: white;
  font-size: 1em;
  font-weight: bold;
  padding: 0.25em 0.5em;
}
.Status button:hover {
  cursor: pointer;
}
.Status button.logout {
  background-color: transparent;
  color: blue;
  padding: 0;
}
.Status button.logout:hover {
  color: purple;
}
.Status a {
  display: inline-block;
  font-weight: bold;
  margin-right: 3em;
}

.Chart {
  border-radius: 3px;
  font-size: 1em;
  font-weight: bold;
  margin: 0;
  padding: 0.5em;
}
.Chart.success {
  background-color: hsla(120, 100%, 25%, 1);
  color: hsla(0, 0%, 100%, 1);
}
.Chart.warn {
  background-color: hsla(39, 100%, 50%, 1);
  color: hsla(0, 0%, 0%, 1);
}
.Chart.fail {
  background-color: hsla(0, 100%, 50%, 1);
  color: hsla(0, 0%, 100%, 1);
}

.map-wrapper {
  height: 500px;
  margin: 0 auto;
  position: relative;
}
#map {
  border-radius: 3px;
  bottom: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.mapFull {
  height: 800px;
}

.MapProgress h4 {
  background: hsla(240, 100%, 50%, 0.075);
  border-radius: 3px;
  color: hsla(240, 100%, 50%, 1);
  display: inline-block;
  font-size: 0.75em;
  font-style: italic;
  font-weight: normal;
  margin: 0 0 1em;
  padding: 0.5em 0.75em;
}

.Stat {
  width: 25%;
}
.Stat h1 {
  font-size: 1.25em;
  margin: 0 0 0.5em 0;
}
.Stat h2 {
  margin: 0;
}
.Stat h2,
.Stat h3,
.Stat h4 {
  color: hsla(0, 0%, 0%, 0.9);
  font-size: 1em;
}

.Stats .Stat h3,
.Stat h4 {
  font-size: 1em;
  margin: 0.5em 0 0;
}

.Stat h4 {
  border-radius: 3px;
  display: inline-block;
  font-size: 0.75em;
  padding: 0.25em 0.5em;
}

.Stat h4.increase {
  background: hsla(120, 100%, 25%, 0.075);
  color: hsla(120, 100%, 25%, 1);
}
.Stat h4.decrease {
  background: hsla(0, 100%, 50%, 0.075);
  color: hsla(0, 100%, 50%, 1);
}
.Stat h4.equal {
  background: hsla(39, 100%, 50%, 0.15);
  color: hsla(0, 59%, 41%, 1);
}
.Stat h4.info {
  background: hsla(240, 100%, 50%, 0.075);
  color: hsla(240, 100%, 50%, 1);
}
.Stat h4.increase:before {
  content: '\2191   ';
}
.Stat h4.equal:before {
  content: '= ';
}
.Stat h4.decrease:before {
  content: '\2193   ';
}

.Stat h4:last-child:before {
  content: '';
}

.Stat .info {
  border: 1px solid;
  border-radius: 50%;
  display: inline-block;
  font-size: 0.75em;
  font-weight: bold;
  height: 20px;
  line-height: 1.2;
  padding: 0.25em;
  text-align: center;
  width: 20px;
}

.Stats {
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.1);
  margin: 0 0 2em;
  padding: 0 0 2em;
  width: 100%;
}
.Stats .wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
}
.Stats:last-child .wrapper {
  border-bottom: none;
  margin-bottom: 0;
}

.Stats h3 {
  font-size: 1.5em;
  margin: 0 0 0.5em 0;
}

.Stats p {
  background: hsla(0, 100%, 50%, 0.075);
  border-radius: 3px;
  color: hsla(0, 100%, 50%, 1);
  display: inline-block;
  font-weight: bold;
  margin: 2em 0 0;
  padding: 0.25em 0.5em;
}

.Dashboard header {
  color: hsla(0, 0%, 0%, 0.9);
  margin-bottom: 3em;
  max-width: 75ch;
}
.Dashboard header h2 {
  margin: 0 0 0.25em;
}
.Dashboard header h3 {
  margin: 0;
}
.Dashboard em {
  font-size: 0.75em;
}
.Dashboard section {
  margin-bottom: 4em;
}

.Feeder {
  margin-top: 2em;
}


.Substation {
  margin-top: 2em;
}

.Substation hr {
  border: none;
  border-top: 1px solid hsla(0, 0%, 0%, 0.1);
  margin: 0;
  max-width: 75ch;
}

.Substations .Summary {
  margin-top: 2em;
}

.Substations .Summary .wrapper {
  background: hsla(0, 0%, 0%, 0.025);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 1em 1em;
}
.Substations .Summary .wrapper div {
  background-color: white;
  margin-top: 1em;
  padding: 1em;
  width: 49%;
}
.Substations .Summary .wrapper div h2 {
  margin: 0;
}
.Substations .Summary .wrapper div p {
  margin-bottom: 1em;
}

.Substations header {
  margin-bottom: 4em;
  max-width: 75ch;
}
.Substations header h2,
.Substations header h3 {
  margin: 0 0 0.25em;
}
.Substations header p:first-of-type {
  color: hsla(210, 13%, 50%, 1);
  font-weight: bold;
  margin: 0;
}

.Substations nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.Substations nav li {
  display: inline-block;
  font-weight: bold;
  margin: 0 3em 20px 0;
}

.SubstationFeeders ul {
  list-style: none;
  margin: 0 0 2em;
  padding: 0;
}
.SubstationFeeders ul li {
  margin-top: 0.5em;
}
.SubstationFeeders ul li:first-child {
  margin-top: 0;
}
.SubstationFeeders h3 {
  margin: 0 0 0.5em;
  padding: 0;
}
.SubstationFeeders ul p {
  color: hsla(0, 0%, 0%, 0.75);
  font-size: 0.85em;
  margin: 0.25em 0 1em;
  padding: 0;
}

.ConfigForm label,
.ConfigForm input,
.ConfigForm button {
  display: block;
}

.ConfigForm label {
  margin-bottom: 0.25em;
}
.ConfigForm input {
  border: 1px solid black;
  border-radius: 3px;
  font-size: 1em;
  margin-bottom: 1em;
  padding: 0.5em;
}
.ConfigForm button {
  background: blue;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  margin-top: 2em;
  padding: 0.5em 0.75em;
}
.ConfigForm button:hover {
  background: green;
}
.ConfigForm button.small {
  font-size: 1em;
  padding: 0;
}
.ConfigForm button.inline {
  background: none;
  color: blue;
  display: inline-block;
  margin: 0;
}
.ConfigForm button.inline:hover {
  color: green;
}

.ConfigForm .alert {
  background: hsla(240, 100%, 50%, 0.075);
  border: 1px solid hsla(240, 100%, 50%, 0.1);
  border-radius: 7px;
  color: hsla(240, 100%, 50%, 1);
  margin-bottom: 1em;
  padding: 1em;
}
.ConfigForm .alert p {
  margin: 0;
}

.AutoDesign .content {
  display: grid;
  grid-column-gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
}

header {
  max-width: 75ch;
}

.client {
  margin-top: 1em;
}

.ClientList h1 {
  margin-top: 1em;
}
.ClientList ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ClientList ul li {
  margin-top: 1.5em;
}
.ClientList ul li:first-child {
  margin-top: 0;
}
.ClientList ul h3 {
  margin: 0;
  padding: 0;
}
.ClientList ul p {
  color: hsla(0, 0%, 0%, 0.75);
  font-size: 0.85em;
  margin: 0.25em 0 1em;
  padding: 0;
}

.diagram header h2 {
  margin-bottom: 0;
}
.diagram header p {
  color: slategray;
  font-size: 0.75em;
  font-style: italic;
  margin-top: 0;
}
.diagram table {
  font-size: 0.75em;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 2em 0;
}
table th {
  font-weight: bold;
}
thead th,
thead td {
  background-color: #f1f1f1;
}
th,
td {
  background-color: #ffffff;
  border: 1px solid #5b616b;
  font-weight: 400;
  padding: 1rem 1.5rem;
  text-align: left;
}
th.center,
td.center {
  text-align: center;
}
td.connection {
  font-family: 'Courier New', Courier, monospace;
}
.capitalize {
  display: block;
  text-transform: capitalize;
}

body {
  color: hsla(0, 0%, 0%, 0.9);
}
a:link,
a:visited {
  color: hsla(240, 100%, 50%, 1);
  text-decoration: none;
}
a:hover {
  color: hsl(300, 100%, 25%, 1);
}
a.active {
  border-bottom: 2px solid;
  color: hsla(0, 100%, 50%, 1);
}

p,
h1,
h2,
h3,
h4,
h5,
li {
  line-height: 1.4;
}

#main {
  margin: 30px auto;
  padding: 0;
  max-width: 1040px;
}

nav {
  margin-bottom: 1em;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav li {
  display: inline-block;
  font-weight: bold;
  margin: 0 3em 20px 0;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

