.Substations .Summary {
  margin-top: 2em;
}

.Substations .Summary .wrapper {
  background: hsla(0, 0%, 0%, 0.025);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0 1em 1em;
}
.Substations .Summary .wrapper div {
  background-color: white;
  margin-top: 1em;
  padding: 1em;
  width: 49%;
}
.Substations .Summary .wrapper div h2 {
  margin: 0;
}
.Substations .Summary .wrapper div p {
  margin-bottom: 1em;
}
