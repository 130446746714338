.Status {
  display: flex;
  flex-flow: row wrap;
}
.Status div {
  width: 50%;
}
.Status .accountActions {
  text-align: right;
}
.Status p {
  display: inline-block;
  margin: 0 1em 0 3em;
}
.Status button {
  background-color: blue;
  border: none;
  border-radius: 3px;
  color: white;
  font-size: 1em;
  font-weight: bold;
  padding: 0.25em 0.5em;
}
.Status button:hover {
  cursor: pointer;
}
.Status button.logout {
  background-color: transparent;
  color: blue;
  padding: 0;
}
.Status button.logout:hover {
  color: purple;
}
.Status a {
  display: inline-block;
  font-weight: bold;
  margin-right: 3em;
}
