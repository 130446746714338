.Stats {
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.1);
  margin: 0 0 2em;
  padding: 0 0 2em;
  width: 100%;
}
.Stats .wrapper {
  display: flex;
  flex-flow: row wrap;
}
.Stats:last-child .wrapper {
  border-bottom: none;
  margin-bottom: 0;
}

.Stats h3 {
  font-size: 1.5em;
  margin: 0 0 0.5em 0;
}

.Stats p {
  background: hsla(0, 100%, 50%, 0.075);
  border-radius: 3px;
  color: hsla(0, 100%, 50%, 1);
  display: inline-block;
  font-weight: bold;
  margin: 2em 0 0;
  padding: 0.25em 0.5em;
}
