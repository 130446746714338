body {
  color: hsla(0, 0%, 0%, 0.9);
}
a:link,
a:visited {
  color: hsla(240, 100%, 50%, 1);
  text-decoration: none;
}
a:hover {
  color: hsl(300, 100%, 25%, 1);
}
a.active {
  border-bottom: 2px solid;
  color: hsla(0, 100%, 50%, 1);
}

p,
h1,
h2,
h3,
h4,
h5,
li {
  line-height: 1.4;
}

#main {
  margin: 30px auto;
  padding: 0;
  max-width: 1040px;
}

nav {
  margin-bottom: 1em;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav li {
  display: inline-block;
  font-weight: bold;
  margin: 0 3em 20px 0;
}

* {
  box-sizing: border-box;
}
