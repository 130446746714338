.ConfigForm label,
.ConfigForm input,
.ConfigForm button {
  display: block;
}

.ConfigForm label {
  margin-bottom: 0.25em;
}
.ConfigForm input {
  border: 1px solid black;
  border-radius: 3px;
  font-size: 1em;
  margin-bottom: 1em;
  padding: 0.5em;
}
.ConfigForm button {
  background: blue;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  margin-top: 2em;
  padding: 0.5em 0.75em;
}
.ConfigForm button:hover {
  background: green;
}
.ConfigForm button.small {
  font-size: 1em;
  padding: 0;
}
.ConfigForm button.inline {
  background: none;
  color: blue;
  display: inline-block;
  margin: 0;
}
.ConfigForm button.inline:hover {
  color: green;
}

.ConfigForm .alert {
  background: hsla(240, 100%, 50%, 0.075);
  border: 1px solid hsla(240, 100%, 50%, 0.1);
  border-radius: 7px;
  color: hsla(240, 100%, 50%, 1);
  margin-bottom: 1em;
  padding: 1em;
}
.ConfigForm .alert p {
  margin: 0;
}
