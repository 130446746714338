.Substations header {
  margin-bottom: 4em;
  max-width: 75ch;
}
.Substations header h2,
.Substations header h3 {
  margin: 0 0 0.25em;
}
.Substations header p:first-of-type {
  color: hsla(210, 13%, 50%, 1);
  font-weight: bold;
  margin: 0;
}

.Substations nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.Substations nav li {
  display: inline-block;
  font-weight: bold;
  margin: 0 3em 20px 0;
}
