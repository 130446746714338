.Stat {
  width: 25%;
}
.Stat h1 {
  font-size: 1.25em;
  margin: 0 0 0.5em 0;
}
.Stat h2 {
  margin: 0;
}
.Stat h2,
.Stat h3,
.Stat h4 {
  color: hsla(0, 0%, 0%, 0.9);
  font-size: 1em;
}

.Stats .Stat h3,
.Stat h4 {
  font-size: 1em;
  margin: 0.5em 0 0;
}

.Stat h4 {
  border-radius: 3px;
  display: inline-block;
  font-size: 0.75em;
  padding: 0.25em 0.5em;
}

.Stat h4.increase {
  background: hsla(120, 100%, 25%, 0.075);
  color: hsla(120, 100%, 25%, 1);
}
.Stat h4.decrease {
  background: hsla(0, 100%, 50%, 0.075);
  color: hsla(0, 100%, 50%, 1);
}
.Stat h4.equal {
  background: hsla(39, 100%, 50%, 0.15);
  color: hsla(0, 59%, 41%, 1);
}
.Stat h4.info {
  background: hsla(240, 100%, 50%, 0.075);
  color: hsla(240, 100%, 50%, 1);
}
.Stat h4.increase:before {
  content: '\2191 ';
}
.Stat h4.equal:before {
  content: '\003D ';
}
.Stat h4.decrease:before {
  content: '\2193 ';
}

.Stat h4:last-child:before {
  content: '';
}

.Stat .info {
  border: 1px solid;
  border-radius: 50%;
  display: inline-block;
  font-size: 0.75em;
  font-weight: bold;
  height: 20px;
  line-height: 1.2;
  padding: 0.25em;
  text-align: center;
  width: 20px;
}
