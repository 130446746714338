.Substation {
  margin-top: 2em;
}

.Substation hr {
  border: none;
  border-top: 1px solid hsla(0, 0%, 0%, 0.1);
  margin: 0;
  max-width: 75ch;
}
