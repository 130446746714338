.SubstationFeeders ul {
  list-style: none;
  margin: 0 0 2em;
  padding: 0;
}
.SubstationFeeders ul li {
  margin-top: 0.5em;
}
.SubstationFeeders ul li:first-child {
  margin-top: 0;
}
.SubstationFeeders h3 {
  margin: 0 0 0.5em;
  padding: 0;
}
.SubstationFeeders ul p {
  color: hsla(0, 0%, 0%, 0.75);
  font-size: 0.85em;
  margin: 0.25em 0 1em;
  padding: 0;
}
