.ClientList h1 {
  margin-top: 1em;
}
.ClientList ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ClientList ul li {
  margin-top: 1.5em;
}
.ClientList ul li:first-child {
  margin-top: 0;
}
.ClientList ul h3 {
  margin: 0;
  padding: 0;
}
.ClientList ul p {
  color: hsla(0, 0%, 0%, 0.75);
  font-size: 0.85em;
  margin: 0.25em 0 1em;
  padding: 0;
}
